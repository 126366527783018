<template>
  <div style="min-height: 800px">
    <subMenu key="subMenu" :activeTab="activeTab" :menuList="menuList"></subMenu>

    <div class="main-box mb-30">
      <div class="box-left">
        <div class="box-header">
          <div style="width: 1200px">
            <el-tabs style="margin-bottom: 20px" v-model="activeName" @tab-click="handleClick">
              <el-tab-pane v-for="(item, index) in newsTitleList" :key="item.industryInformationClassName"
                :label="item.industryInformationClassName" :name="item.industryInformationClassName">
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div @click="handItem(item)" class="left-new-item" v-for="(item, index) in newsList" :key="index" style="  cursor: pointer;">
          <div class="left-new-image">
            <el-image style="width: 210px; height: 140px; margin-right: 30px"
              :src="item.informationPictureUrl"></el-image>
          </div>
          <div class="left-text">
            <h3 style="width: 550px; text-align: start" class="fz-20 mb-10 a-line1">
              {{ item.informationTitle }}
            </h3>
            <div class="c-gray mb-10 a-line2">
              <div>{{ item.brief }}</div>
            </div>
            <div class="flex-between c-gray">
              <div>来源：邦建通大数据平台</div>
              <div v-if="item.createTime">
                {{ item.createTime.split(" ")[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="box-right mb-30">
          <div class="box-right-header flex-between">
            <span class="fw-b">建设要闻</span><span @click="handMore(1)">更多>></span>
          </div>
          <div class="right-new-box">
            <div v-for="(item, index) in newsList" :key="index">
              <div class="flex-between c-gray no-white mb-20 right-new-item">
                <div class="w-line1">{{ item.informationTitle }}</div>
                <div class="" v-if="item.createTime">
                  {{ item.createTime.split(" ")[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-right mb-30">
          <div class="box-right-header flex-between">
            <span class="fw-b">各行业信息</span><span @click="handMore(2)">更多>></span>
          </div>
          <div class="right-new-box">
            <div v-for="(item, index) in newList" :key="index">
              <div class="flex-between c-gray no-white mb-20 right-new-item">
                <div class="w-line1">{{ item.title }}</div>
                <div class="" v-if="item.createTime">
                  {{ item.time.split(" ")[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-right mb-30">
          <div class="box-right-header flex-between">
            <span class="fw-b">部发文件</span><span @click="handMore(3)">更多>></span>
          </div>
          <div class="right-new-box">
            <div v-for="(item, index) in newsList" :key="index">
              <div class="flex-between c-gray no-white mb-20 right-new-item">
                <div class="w-line1">{{ item.informationTitle }}</div>
                <div class="" v-if="item.createTime">
                  {{ item.createTime.split(" ")[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block mb-30">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="10"
        :current-page.sync="current" layout="prev, pager, next, jumper" :total="this.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import subMenu from "@/components/sub-menu/sub-menu.vue";
import videoProduct from "../components/video/video.vue";
import newProduct from "../components/news/news.vue";
import ecosystemList from "../components/ecosystemList/ecosystemList.vue";
import partner from "../components/partner/partner.vue";
import { mapState } from "vuex";
import {
  getIndustryInformationPage,
  industryInformationCalssList,
  newsPage,
} from "@/api/engineering";

import LoginForm from "@/components/login-form";
export default {
  data() {
    return {
      activeName: "推荐",
      showLoginForm: false,
      companyList: [],
      videoUrlList: [],
      newsList: [],
      navActive: 0,
      menuList: [
        { label: "首页", name: "home", url: "" },
        { label: "应用系统", name: "ecosystem", url: "" },
        { label: "行业资讯", name: "news", url: "" },
        { label: "荣誉证书", name: "books", url: "" },
        { label: "战略合作", name: "partner", url: "" },
        { label: "联系我们", name: "about", url: "" },
      ],
      activeTab: "news",
      serviceList: [],
      currentIndex: 0,
      intervalId: null,
      navActive: 0,
      newsTitleList: [],
      industryInformationClassId: -1,
      current: 1,
      size: 9,
      newList: [],
      total: 999,
    };
  },
  created() { },
  filters: {
    unescape: function (html) {
      if (html) {
        return html.replace(html ? /&(?!#?\w+;)/g : /&/g, '&amp;')
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, "\"")
          .replace(/&#39;/g, "\'");
      } else {
        return html
      }

    }
  },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    optionHover() {
      return {
        activeName: "second",
      };
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  components: {
    LoginForm,
    subMenu,
    videoProduct,
    newProduct,
    ecosystemList,
    partner,
  },
  mounted() {
    document.title = "行业资讯";
    this.getIndustryInformation();
    this.industryInformationCalssList();
    this.newsPage();
  },
  methods: {
    // 获取各地信息
    async newsPage() {
      try {
        const { data } = await newsPage({
          childType: "各地信息",
          size: 20,
          type: "各地信息",
        });
        this.newList = data.records;
      } catch (error) {
        console.log("newsPageError", error);
      }
    },
    handleClick(tab, event) {
      this.current = 1;
      let items = this.newsTitleList.filter(
        (item) => item.industryInformationClassName == tab.name
      )[0];
      this.industryInformationClassId = items.industryInformationClassId;
      this.getIndustryInformation();
    },
    handleSizeChange(e) {
      this.size = e;
      this.getIndustryInformation();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getIndustryInformation();
    },
    currentPage2() { },
    handMore(e) {
      let url = null;
      if (e) {
        url = "/moreNew/moreNew";
      }
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      window.open(vRouter.href + "?" + e, "_blank");
    },
    async industryInformationCalssList() {
      try {
        const { data } = await industryInformationCalssList({
          current: 1,
          size: 20,
        });
        let records = data.industryInformationCalssList || [];
        this.newsTitleList = records;
        console.log("dasdadxxxxasd", this.newsTitleList);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },

    async getIndustryInformation() {
      try {
        const { data } = await getIndustryInformationPage({
          current: this.current,
          size: this.size,
          industryInformationClassId: this.industryInformationClassId,
        });
        let records = data.records || [];
        this.$nextTick((res) => {
          this.total = data.total;
        });
        records.forEach((element) => {
          let updateTime = element.updateTime || "";
          element.dateText = updateTime;
          if (updateTime) {
            element.dateText = updateTime.split(" ")[0];
          }
        });
        this.newsList = records;
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    handNavItem(item, index) {
      this.industryInformationClassId = item.industryInformationClassId;
      this.navActive = index;
      this.getIndustryInformation();
    },

    handItem(item) {
      let theUrlPrefix = window.location.origin;
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      }
      if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }
      let url =
        theUrlPrefix +
        "/api/v1/industry/detail?industryId=" +
        item.informationId;
      console.log("xxxxxsss", url);
      window.open(url, "_blank");
    },
  },
};
</script>

<style>
/* 进入动画 */
.v-enter-active {
  animation: move 1s;
}

/* 离开动画 */
.v-leave-active {
  animation: move 1s reverse;
}

@keyframes move {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translate(0);
  }
}
</style>

<style scoped lang="less">
.flex-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.main-box {
  padding-top: 30px;
  box-sizing: border-box;
  width: 1520px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}

.main-box {
  width: 1520px;
}

.new-list {
  width: 930px !important;
  height: 2000px !important;
}

.fz-20 {
  font-size: 20px;
}

.c-gray {
  color: #888888;
  font-size: 16px;
  text-align: start;
}

.box-header {
  width: 930px;
  overflow-x: hidden;
  height: 60px;
  overflow-y: hidden;
  font-weight: bolder;
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  border-radius: 20px;
  height: 8px;
  background-color: #dfe8f3;
  width: 0;
}

.box-left {
  width: 930px;
  overflow: hidden;
}

.left-new-item {
  display: flex;
  margin-bottom: 20px;
}

.left-new-item:first-child {
  padding-top: 20px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.left-text {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 663px;
}

.left-new-image {
  border: 1px solid rgb(247, 245, 245);
  border-radius: 10px;
  margin-right: 30px;
}

.mb-20 {
  margin-bottom: 34px;
}

.box-right {
  width: 557px;
  height: 516px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #dce5f2;
  overflow: auto;
}

.no-white {
  white-space: nowrap;
}

.box-right-header {
  width: 100%;
  top: 0;
  position: sticky;
  background-color: #edf4ff;
  height: 60px;
  line-height: 20px;
  padding: 20px;
  cursor: pointer;
  color: #888;
}

.fw-b {
  font-weight: bold;
  color: #212121;
}

.right-new-box {
  padding: 20px;
}

.w-line1 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.flex-grow {
  flex-grow: 1;
}

.w-line2 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.w-line3 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.mb-30 {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.right-new-item {
  cursor: pointer;
}

.right-new-item:hover {
  color: #2288fc;
}

/* 溢出隐藏 */
.a-line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mb-10 {
  margin-bottom: 15px;
}

.a-line2 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.a-line3 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}
</style>
